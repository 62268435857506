import {
  ArrowCircleDownOutlined,
  ArrowCircleUpOutlined,
  Download,
  Refresh,
  Search,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery, Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getWirelessTroubleshoot } from '../../API/WifiEvents/wifi'
import { UserContext } from '../../context/UserContext';
import { defaultAgGridProps } from '../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../utils/columnDef';
import { timeSince, toCamelizeWords } from '../../utils/formatString';
import { fDateTimeForDownloadCsv } from '../../utils/formatTime';
import ArrayToCSVdownload from '../ArrayToCSVdownload';
import ColumnSettingsDialog from '../InventoryComponent/components/filters/ColumnSettingsDialog';
import { ActionRender, RetroubleShootRender } from './renderer';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from '@mui/material';
import AddMacDialog from './AddMacDialog';

// ---------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  icon: {
    visibility: 'hidden',
    minWidth: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'left',
  },
  progressStyle: {
    position: 'absolute',
    left: 13,
    top: 18,
  },
}));

// ----------------------------------------------------------------------

const Wifi = (props) => {
  const { locationData, deviceTypes, setDeviceTypes, setVendorsType, vendorsType, isWanSelected } = props;

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [userCustomer, setUserCustomer] = useState({});
  const [filterModel, setFilterModel] = useState('');
  const [sortModel, setSortModel] = useState('');
  const [insightDetails, setInsightDetails] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [addMacDialog, setAddMacDialog] = useState(false);
  const refreshStats = useSelector((state) => state.statCounter.refreshStats);
  let controller = new AbortController();
  const locationDataRef = useRef(locationData);

  useEffect(() => {
    locationDataRef.current = locationData;
  }, [locationData]);

  const theme = useTheme();
  const is1024px = useMediaQuery(theme.breakpoints.only('md'));
  const is1440px = useMediaQuery('(min-width:1440px) and (max-width:2559px)');
  const is2560px = useMediaQuery('(min-width:2560px)');

  const fontSize = is2560px ? '20px' : is1440px ? '16px' : is1024px ? '14px' : '12px';

  useEffect(() => {
    if (gridApi) {
      if (controller) {
        controller.abort();
        controller = new AbortController();
      }
      gridApi.purgeInfiniteCache();
    }
  }, [refreshStats, locationData]);

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
        deviceType: deviceTypes,
        vendor: vendorsType,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData, deviceTypes, vendorsType]);

  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-network-inventory-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  const isUndefined = (value, def = '') => {
    return value === undefined || value == null || value.length <= 0 || value === '' ? def : value;
  };

  const columnHeaders = [
    {
      headerName: '#',
      colId: 'id',
      width: 90,
      // pinned: 'left',
      floatingFilter: false,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return <Skeleton variant="rectangular" height={30} style={{ margin: "7px 0" }} />
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      valueGetter: (params) => {
        const id = params.node.id;
        if (params.node.id === undefined) {
          return '';
        }
        if (params.node.id === 0) {
          return '';
        }
        return parseInt(params.node?.id, 10) + 1;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Client MAC Address',
      colId: 'client_mac_address',
      sortable: true,
      width: 250,
      // pinned: 'left',
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      filterParams: {
        filterOptions: ['CONTAINS'],
        defaultOption: 'CONTAINS',
        suppressAndOrCondition: true,
        debounceMs: 300,
      },
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }
        return params.data?.client_mac_address;
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Client Name',
      colId: 'user_name',
      sortable: true,
      width: 200,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        const troubleshootResult = params.data?.troubleshoot_result;
        if (params.data === undefined) {
          return '';
        }
        else if (params.data.troubleshoot_status === 0 && troubleshootResult === null) {
          return "analysing...."
        }

        if (Array.isArray(troubleshootResult) && troubleshootResult.length > 0 && troubleshootResult !== null) {
          return troubleshootResult[0]?.client_name || '';
        }

        return '';
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },

    {
      headerName: 'Connected AP',
      colId: 'connected_ap_name',
      sortable: true,
      width: 200,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },

      valueGetter: (params) => {
        const troubleshootResult = params.data?.troubleshoot_result;
        if (params.data === undefined) {
          return '';
        }
        else if (params.data.troubleshoot_status === 0 && troubleshootResult === null) {
          return "analysing...."
        }

        if (Array.isArray(troubleshootResult) && troubleshootResult.length > 0 && troubleshootResult !== null) {
          return troubleshootResult[0]?.connected_ap_name || '';
        }

        return '';
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Location',
      colId: 'location_name',
      sortable: true,
      width: 200,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        const troubleshootResult = params.data?.troubleshoot_result;
        if (params.data === undefined) {
          return '';
        }
        else if (params.data.troubleshoot_status === 0 && troubleshootResult === null) {
          return "analysing...."
        }

        if (Array.isArray(troubleshootResult) && troubleshootResult.length > 0 && troubleshootResult !== null) {
          return troubleshootResult[0]?.location_name || '';
        }

        return '';
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'SSID',
      colId: 'SSID_name',
      sortable: true,
      width: 200,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        const troubleshootResult = params.data?.troubleshoot_result;
        if (params.data === undefined) {
          return '';
        }
        else if (params.data.troubleshoot_status === 0 && troubleshootResult === null) {
          return "analysing...."
        }

        if (Array.isArray(troubleshootResult) && troubleshootResult.length > 0 && troubleshootResult !== null) {
          return troubleshootResult[0]?.SSID_name || '';
        }

        return '';
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Signal Strength',
      colId: 'Signal_strength',
      sortable: true,
      width: 200,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        const troubleshootResult = params.data?.troubleshoot_result;
        if (params.data === undefined) {
          return '';
        }
        else if (params.data.troubleshoot_status === 0 && troubleshootResult === null) {
          return "analysing...."
        }

        if (Array.isArray(troubleshootResult) && troubleshootResult.length > 0 && troubleshootResult !== null) {
          return troubleshootResult[0]?.Signal_strength || '';
        }

        return '';
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Client IP Address',
      colId: 'client_ip_address',
      sortable: true,
      width: 200,
      floatingFilter: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        const troubleshootResult = params.data?.troubleshoot_result;
        if (params.data === undefined) {
          return '';
        }
        else if (params.data.troubleshoot_status === 0 && troubleshootResult === null) {
          return "analysing...."
        }

        if (Array.isArray(troubleshootResult) && troubleshootResult.length > 0 && troubleshootResult !== null) {
          return troubleshootResult[0]?.client_ip_address || '';
        }

        return '';
      },
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
    {
      headerName: 'Co-pilot Summary',
      colId: 'co_pilot_summary',
      floatingFilter: false,
      width: 500,
      // pinned: 'left',
      sortable: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      valueGetter: (params) => {
        const troubleshootResult = params.data?.troubleshoot_result;
        if (params.data === undefined) {
          return '';
        }
        else if (params.data.troubleshoot_status === 0 && troubleshootResult === null) {
          return "analysing...."
        }

        if (Array.isArray(troubleshootResult) && troubleshootResult.length > 0 && troubleshootResult !== null) {
          return troubleshootResult[0]?.co_pilot_summary || '';
        }

        return '';
      },
      cellStyle: {
        fontSize, fontFamily: 'Arial', fontWeight: '400', whiteSpace: 'normal',  // Enables multi-line text
        lineHeight: '1.5'
      },

    },

    {
      headerName: 'Insights',
      colId: 'insights',
      floatingFilter: false,
      width: 250,
      pinned: 'right',
      sortable: false,
      filter: false,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
      cellRenderer: 'actionRender',
      cellStyle: { fontSize, fontFamily: 'Arial', fontWeight: '400' },
    },
  ];

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };



  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    params.api.setDatasource({
      rowCount: null, // Let AG Grid infer the row count dynamically
      context: {
        global_keyword: query,
        deviceType: deviceTypes,
        vendor: vendorsType,
      },
      getRows: async (_params) => {
        setRefreshLoading(true);
        params.api.hideOverlay();

        const payload = {
          ..._params,
          global_keyword: _params.context.global_keyword,
          location_uuids: locationDataRef.current,
          deviceType: _params.context.deviceType,
          vendor: _params.context.vendor,
          filterModel: {
            ..._params.filterModel,
          },
        };

        if (_params.context.vendor) {
          payload.filterModel.l_dvc_typ_vendor = {
            filterType: 'text',
            type: 'CONTAINS',
            filter: _params.context.vendor,
          };
        }

        if (_params.context.deviceType) {
          payload.filterModel.l_dvc_typ_name = {
            filterType: 'text',
            type: '=',
            filter: _params.context.deviceType === "Unknown" ? "" : _params.context.deviceType,
          };
        }

        delete payload.context;
        setFilterModel(payload.filterModel);
        setSortModel(_params.sortModel);

        // try {
        //   const inventoryData = await getWirelessTroubleshoot(customerId, payload);

        //   const rowData = inventoryData.data || [];
        //   const totalRecords = inventoryData.total || 0;

        //   let lastRow = totalRecords <= _params.endRow ? totalRecords : -1;

        //   setTotalItems(totalRecords);
        //   if (totalRecords === 0) {
        //     params.api.showNoRowsOverlay();
        //   } else {
        //     params.api.hideOverlay();
        //   }

        //   _params.successCallback(rowData, lastRow);
        //   setInsightDetails(inventoryData);

        //   const intervalId = setInterval(() => getWirelessTroubleshoot(customerId, payload), 20000);
        //   return () => clearInterval(intervalId); // Cleanup on component unmount.

        // } 
        try {
          const inventoryData = await getWirelessTroubleshoot(customerId, payload);
        
          const rowData = inventoryData.data || [];
          const totalRecords = inventoryData.total || 0;
        
          let lastRow = totalRecords <= _params.endRow ? totalRecords : -1;
        
          setTotalItems(totalRecords);
          if (totalRecords === 0) {
            params.api.showNoRowsOverlay();
          } else {
            params.api.hideOverlay();
          }
        
          _params.successCallback(rowData, lastRow);
          setInsightDetails(inventoryData);
        
          const intervalId = setInterval(async () => {
            try {
              const updatedData = await getWirelessTroubleshoot(customerId, payload);
              setInsightDetails(updatedData);
            } catch (error) {
              console.error('Error during interval refresh:', error);
            }
          }, 100000);
          
          // Cleanup interval on unmount
          return () => clearInterval(intervalId);
        
        }
        catch (e) {
          _params.failCallback();
        } finally {
          setRefreshLoading(false);
        }
      },
    });
  }, [query, deviceTypes, vendorsType, getWirelessTroubleshoot, customerId, controller]);

  
  const onBtnExport = () => {
    setDownloadLoading(true);
    const inventory = [];
    const pages = Math.ceil(totalItems / 200);

    const promises = Array(pages)
      .fill()
      .map((item, index) => {
        const startIndex = index * 200;
        const endIndex = Math.min((index + 1) * 200, totalItems + 1);
        const payload = {
          endRow: endIndex,
          startRow: startIndex,
          location_uuids: locationData,
          deviceType: deviceTypes,
          global_keyword: query,
          sortModel,
          filterModel,
        };
        return getWirelessTroubleshoot(customerId, payload);
      });

    const allowedColumns = columnDefs.filter((def) => !def.hide).map((def) => def.colId);


    try {
      Promise.all(promises)
        .then((res) => {
          res.forEach((item, index) => {
            console.log(`Page ${index + 1} returned ${item.data?.length || 0} records`);
            item.data?.forEach((device) => {
              const record = {};
              allowedColumns.forEach((column) => {
                switch (column) {
                  case 'r_dvc_ip_address':
                    record['IP Address'] = device?.r_dvc_ip_address ?? '';
                    break;
                  case 'r_location_name':
                    record['Location'] = device?.r_location_name ?? '';
                    break;
                  case 'r_dvc_name':
                    record['Device Name'] = device?.r_dvc_name ?? '';
                    break;
                  case 'r_dvc_model':
                    record['Device Model'] = device?.r_dvc_model ?? '';
                    break;
                  case 'r_dvc_mac_address':
                    record['Mac Address'] = device?.r_dvc_mac_address ?? '';
                    break;
                  case 'r_dvc_serial_number':
                    record['Serial Number'] = device?.r_dvc_serial_number ?? '';
                    break;
                  case 'l_dvc_typ_vendor':
                    record['Vendor'] = device?.l_dvc_typ_vendor ?? '';
                    break;
                  case 'l_dvc_typ_name':
                    record['Device Type'] = device?.l_dvc_typ_name ?? '';
                    break;
                  case 'r_dvc_firmware':
                    record['Firmware Version'] = device?.r_dvc_firmware ?? '';
                    break;
                  case 'r_dvc_eos':
                    record['EOS'] = device?.r_dvc_eos || 'Not Announced';
                    break;
                  case 'r_dvc_eol':
                    record['EOL'] = device?.r_dvc_eol || 'Not Announced';
                    break;
                  case 'r_dvc_is_vulnerable':
                    record['Vulnerable'] = device?.r_dvc_is_vulnerable || 'No';
                    break;
                  case 'r_dvc_description':
                    record['Description'] = device?.r_dvc_description ?? '';
                    break;
                  case 'item.r_dvc_error':
                    record['Information'] = device?.r_dvc_error ?? '';
                    break;
                  case 'r_dvc_uptime':
                    record['Uptime'] = device?.r_dvc_uptime ?? '';
                    break;
                  default:
                    break;
                }
              });
              inventory.push(record);
            });
          });
        })
        .then(() => {
          if (inventory.length > 0) {
            const CSV_SEPARATER = ',';
            ArrayToCSVdownload({
              data: inventory,
              headers: Object.keys(inventory[0]),
              delimiter: CSV_SEPARATER,
              filename: `NetworkDevices-${userCustomer}-${fDateTimeForDownloadCsv(new Date())}.csv`,
            });
          }

          setDownloadLoading(false);
          enqueueSnackbar('Downloaded successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        })
        .catch((error) => {
          console.error('Error:', error);
          setDownloadLoading(false);
          enqueueSnackbar('Error occurred while exporting data', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        });
    } catch (error) {
      console.error('Error:', error);
      setDownloadLoading(false);
      enqueueSnackbar('Error occurred while exporting data', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };


  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      suppressMenu: true,
      floatingFilter: true,
    };
  }, []);

  const components = useMemo(() => ({
    //   vulnerableRenderer: VulnerableRenderer,
    //   ipRenderer: IpRenderer,
    actionRender: ActionRender,
  }), []);


  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-network-inventory-column', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-network-inventory-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };


  const handleOpenDialog = () => {
    setAddMacDialog(true)
  }

  const handleClose = () => setAddMacDialog(false)
  // ----------------------------------------------------------------------
  return (
    <>
      <Card id="ndTable">
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '5px' }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Chip label={totalItems} />
                {refreshLoading && <CircularProgress size={35} className={classes.progressStyle} />}
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}>
                  Wireless TroubleShoot:
                  <span className={classes.titleDown}> {totalItems} </span>
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px', gap: 2 }}
              >

                <Button variant="outlined" onClick={() => handleOpenDialog()}   >Add Mac Address</Button>
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search devices"
                />
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <IconButton color="primary" onClick={handleRefresh}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Box>
                  <Tooltip title="Column settings">
                    <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                      <Settings sx={{ fontSize: '2rem' }} />
                    </IconButton>
                  </Tooltip>
                  {openColSettingsDialog && (
                    <ColumnSettingsDialog
                      gridApi={gridApi}
                      columnDefs={columnDefs}
                      openDialog={openColSettingsDialog}
                      resetColumnSetting={resetColumnSetting}
                      handleClose={() => setOpenColSettingsDialog(false)}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '600px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowHeight={150} // Set your desired row height here
            defaultColDef={defaultColDef}
            components={components}
            onDragStopped={onDragStopped}
            suppressDragLeaveHidesColumns={true} // Optional, prevents hidden columns during drag
            suppressColumnVirtualisation={false} // Ensures pinned columns render
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
              deviceType: deviceTypes,
              vendor: vendorsType,
              insightDetails: insightDetails
            }}
          />
        </Box>
      </Card>
      {addMacDialog && <AddMacDialog openDialog={addMacDialog} handleClose={handleClose} gridApi={gridApi} />}
    </>

  );
};
export default Wifi;