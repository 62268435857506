import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button, Dialog,
  DialogActions, DialogContent, DialogTitle, Divider,
  IconButton, LinearProgress, List, Table, TableBody,
  TableCell,
  TableHead, TableRow, Typography, TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useDropzone } from 'react-dropzone';
import { createIpam } from '../../../API/IPAM/Ipam';
// import FiletoDownload from '../../../Assets/';
import FiletoDownload from '../../../Assets/IPAM_template.xlsx'
import { UserContext } from '../../../context/UserContext/userContext';
import * as XLSX from 'xlsx';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function UploadIPAM(props) {
  const classes = useStyles();
  const { openDialog, handleClose ,gridApi} = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [sheetData, setSheetData] = useState([]); // Store data from all sheets
  const [currentSheetIndex, setCurrentSheetIndex] = useState(0); // To track current sheet being viewed
  const [showText, setShowText] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [filteredData, setFilteredData] = useState([])
  const [hasErrors, setHasErrors] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]); // To track which fields have errors
  const [loading, setLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false); // New state for tracking upload completion
  const [uploadComplete, setUploadComplete] = useState(Array(sheetData.length).fill(false)); // Track upload state for each sheet


  // --------------------------------------------------------------------------------------------
  const CSV_SEPARATER = ',';

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Get the first sheet name
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];

        // Convert the first sheet to JSON
        const sheetJson = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        // Extract header and data
        const header = [...sheetJson[0]]; // First row as header
        const parsedSheet = sheetJson.slice(1).filter(row =>
          row.some(cell => cell !== undefined && cell.toString().trim() !== "")
        ); // Skip header and filter out empty rows

        // Map the parsed data to objects using the header
        const mappedData = parsedSheet.map((row) => {
          const rowObject = {};
          header.forEach((col, index) => {
            rowObject[col] = row[index] || ""; // Map each header to row data, defaulting to an empty string
          });
          return rowObject;
        });

        // Set state to display the data
        setSheetData([{ header, data: parsedSheet }]); // Set the header and data for the first sheet
        setFilteredData(mappedData); // Set the filtered data for display
        setShowText(false);
        setShowTable(true);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);



  const validateFields = () => {
    const currentSheet = sheetData[currentSheetIndex].data;
    const errors = [];

    // Iterate over each row of the current sheet
    currentSheet.forEach((row, rowIndex) => {
      const rowErrors = [];

      row.forEach((cell, cellIndex) => {
        const header = sheetData[currentSheetIndex].header[cellIndex];

        if (header !== 'No' && header !== 'message') {
          // Handle validation for different types of cells
          let cellValue = cell || ''; // Ensure null/undefined is treated as an empty string

          // If cell is an object (e.g., an array, number, or something else), convert to a string
          if (typeof cellValue !== 'string') {
            cellValue = String(cellValue);
          }

          // Perform trim validation only on strings
          if (cellValue.trim() === '') {
            rowErrors[cellIndex] = true; // Mark as error
          } else {
            rowErrors[cellIndex] = false; // No error
          }
        }
      });

      errors.push(rowErrors); // Collect errors for each row
    });

    setFieldErrors(errors); // Update state
    setHasErrors(errors.some(row => row.includes(true))); // Check if there are any errors
  };



  const handleAddRow = () => {
    const updatedSheetData = [...sheetData];
    const currentSheet = updatedSheetData[currentSheetIndex];

    // Create an empty row with the same number of columns as the header
    const emptyRow = Array(currentSheet.header.length).fill("");
    currentSheet.data.push(emptyRow);

    setSheetData(updatedSheetData); // Update state with the new row
  };

  const handleDeleteRow = (rowIndex) => {
    const updatedSheetData = [...sheetData];
    const currentSheet = updatedSheetData[currentSheetIndex];

    // Remove the row at the given index
    currentSheet.data.splice(rowIndex, 1);

    setSheetData(updatedSheetData); // Update state after deletion
  };


  const renderTable = (sheet) => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Actions</TableCell> {/* Add a new column for actions */}
          {sheet.header?.map((headerCell, index) => (
            <TableCell key={index}>{headerCell}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sheet.data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            <TableCell>
              {/* RemoveCircleIcon to delete the current row */}
              <IconButton
                onClick={() => handleDeleteRow(rowIndex)}
                color="error"
                aria-label="delete-row"
              >
                <RemoveCircleIcon />
              </IconButton>
            </TableCell>
            {row.map((cell, cellIndex) => (
              <TableCell key={cellIndex}>
                {sheet.header[cellIndex] === 'No' ? (
                  <span>{cell || ""}</span> // Non-editable 'No' field
                ) : sheet.header[cellIndex] === 'message' ? (
                  <span
                    style={{
                      color: cell === "Successfully Created" ? "green" : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {cell || ""}
                  </span> // Non-editable 'message' field with styling
                ) : (
                  <TextField
                    value={cell || ""}
                    onChange={(e) => handleCellChange(e, rowIndex, cellIndex)} // Handle changes for other fields
                    variant="outlined"
                    fullWidth
                    required
                    error={fieldErrors[rowIndex]?.[cellIndex] || false} // Highlight field if error
                    helperText={
                      fieldErrors[rowIndex]?.[cellIndex]
                        ? `${sheet.header[cellIndex]} is required` // Dynamic field name in the error message
                        : ''
                    }
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const handleCellChange = (e, rowIndex, cellIndex) => {
    const updatedSheetData = [...sheetData];
    const currentSheet = updatedSheetData[currentSheetIndex].data;

    // Update the specific cell in the current sheet
    const newValue = e.target.value;
    currentSheet[rowIndex][cellIndex] = newValue;

    // Set the updated sheet data back to the state
    setSheetData(updatedSheetData);

    // Run validation to check for errors
    validateFields();
  };


 
  const handleSubmit = async () => {
    const currentSheetData = sheetData[currentSheetIndex].data;
  
    if (currentSheetData.length === 0) {
      enqueueSnackbar("No data to add", {
        variant: "warning",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      return;
    }
  
    setLoading(true);
    try {
      const currentSheetFilteredData = currentSheetData.map((row) => {
        const rowObject = {};
        sheetData[currentSheetIndex].header.forEach((headerCell, index) => {
          rowObject[headerCell] = row[index] || "";
        });
        return rowObject;
      });
  
      const updatedSheetData = await Promise.all(
        currentSheetFilteredData.map(async (item) => {
          try {
            const response = await _addIpam(item);            
            item.message = "Successfully Created"; // Add message
            return item;
          } catch (error) {
            console.log("error",error);
            
            item.message = error.response?.data?.error || error.error; // Add error message
            return item;
          }
        })
      );
  
      // Add 'message' to headers if not already present
      const updatedHeaders = [...sheetData[currentSheetIndex].header];
      if (!updatedHeaders.includes("message")) {
        updatedHeaders.push("message");
      }
  
      const updatedSheets = [...sheetData];
      updatedSheets[currentSheetIndex] = {
        ...updatedSheets[currentSheetIndex],
        header: updatedHeaders, // Update headers with 'message'
        data: updatedSheetData.map((item) =>
          updatedHeaders.map((header) => item[header] || "")
        ),
      };
  
      setSheetData(updatedSheets);
  
      const newUploadComplete = [...uploadComplete];
      newUploadComplete[currentSheetIndex] = true;
      setUploadComplete(newUploadComplete);
  
      setIsUploadComplete(true);
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message || error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  
 
  
  const _addIpam = async (value) => {    
    const data = {
      location: value.Location,
      subnet_value: value.Subnet , 
      region: value.Region,
      remarks: value.Remarks,
    };
  
    return await createIpam(customerId, data);
  };
  

 
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      'text/csv': [
        '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ],
    },
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  // --------------------------------------------------------------------------------------------
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Upload IPAM File
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <Close sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        {loading && <LinearProgress />}
        <Divider />
        <DialogContent sx={{ px: 5, py: 3 }}>
          <Box
            {...getRootProps({
              onClick: (event) => {
                event.stopPropagation();
                document.querySelector('input[type="file"]').click();
              },
              style,
            })}
          >
            <input {...getInputProps()} />
            {isDragActive ? 'Drop file here!' : 'Click here or drag and drop the file to upload!'}
            {isDragReject && 'File type not supported!'}
          </Box>
          <Box sx={{ py: 2 }}>
            {showText && (
              <Box>
                <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', marginTop: '3vh' }}>
                  File information will be shown here after the import
                </Typography>
              </Box>
            )}
            {showTable && (
              <div>
                <Typography variant="h6">Preview: IPAM</Typography>

                {renderTable(sheetData[currentSheetIndex])}

                {/* Container for buttons */}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>

                  {/* Left: Previous and Next buttons */}
                  <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={handleAddRow}
                    >
                      Add Row
                    </Button>
                  </Box>
                  {/* Right: Save & Upload button */}
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={uploadComplete[currentSheetIndex] || loading}
                  >
                    Save & Upload
                  </LoadingButton>

                </div>
              </div>
            )}

          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button>
            <a href={FiletoDownload} download="IPAM_template.xlsx">
              Download sample file
            </a>
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>


    </>
  );
}