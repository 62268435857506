

import { TestbedInstance ,instance as modem } from '../modem';

export const getIpam = async (id, data) => {
    const newPromise = modem.post(`${id}/ipam/all`,data).then((response) => { 
     
           
      return response.data.data;
    });
    return newPromise;
  };

export const createIpam = async (id, data) => {
  const newPromise = modem.post(`${id}/ipam`,data).then((response) => {    
    return response.data;
  });
  return newPromise;
};

export const updateIpam = async (customerId, data , id) => {
    const newPromise = modem.put(`${customerId}/ipam/${id}`,data).then((response) => {
      
      
      return response.data;
    });
    return newPromise;
  };

  export const deleteIpam = async (customerId, id) => {
    const newPromise = modem.delete(`${customerId}/ipam/${id}`).then((response) => {
      return response.data;
    });
    return newPromise;
  };

  export const multiUploadIpam = async (id, data) => {
    const newPromise = modem.post(`${id}/ipam`,data).then((response) => {
      return response.data.data;
    });
    return newPromise;
  };
