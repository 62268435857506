import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { deleteIpam } from '../../../../API/IPAM/Ipam';
import { WithConfirm } from '../../../WithConfirm';
import EditCredentials from '../../Components/EditCredentials';
import EditIPAM from '../../Components/EditIPAM';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------


const ActionRender = (props) => {
  const { data, confirm } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const [editIpamDialog, setEditIpamDialog] = useState(false);
  const [ipamLoading, setIpamLoading] = useState(false);
  const [ipamData, setCredentialData] = useState(null);



  const handlecloseEditCredentialDialog = () => {
    setEditIpamDialog(false);
  };

  const handleEdit = (value) => {
    setEditIpamDialog(true);
    setCredentialData(value);
  };

  const handleDelete = (value) => {
    setIpamLoading(true);
    deleteIpam(customerId, value.id)
      .then((res) => {
        props.api.purgeInfiniteCache();
        setIpamLoading(false);
        enqueueSnackbar(
          res.message,
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
          );
      })
      .catch((err) => {
        setIpamLoading(false);
        enqueueSnackbar(
          err.error,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  if (props.data === undefined) {
    return '';
  }

  if (props?.data) {
    return (
      <div style={{ textAlign: 'left' }}>
        <Tooltip title="Edit">
              <IconButton onClick={() => handleEdit(data)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
               onClick={confirm((e) => handleDelete(data), {
                title: 'Information',
                confirmationText: 'Yes',
                disableOkBtn: false,
                description: 'Are you sure you want to delete this IP details?',
              })}
              >
                <Delete />
              </IconButton>
            </Tooltip>
              {editIpamDialog && (
                <EditIPAM
                  openDialog={editIpamDialog}
                  handleClose={handlecloseEditCredentialDialog}
                  ipamData={ipamData}
                  api={props.api}
                />
            )}
      </div>
    )
  }
};


ActionRender.propTypes = {
  data: PropTypes.object,
};

export default WithConfirm(ActionRender);
