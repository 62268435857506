
import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Slide,
  Stack
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { addMember } from '../../../API/Members/members';
import { getRoles } from '../../../API/Roles/roles';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField } from '../../hook-form';
import MemberRoleSelector from './MemberRoleSelector';

// ------------------------------------------------------------------------------------

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

// ------------------------------------------------------------------------------------

export default function AddMemberDialog(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, handleClose, gridApi } = props;
  const { customerId } = useContext(UserContext);
  const [memberRole, setMemberRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const page = 0;
  
  // ---------------------------------------------------------------------------------------------------------------------

  const fetchRoles = useCallback(async () => {
    const res = await getRoles(customerId, page + 1);
    setRoleOptions(res.data);
  }, [customerId, page]);

  useEffect(() => {    
    fetchRoles();
  }, [fetchRoles]);

  // ------------------------------------------------------------------------------------

  const membersSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords do not match'),
    roleSelector: Yup.mixed().required('Role is required')
  });

  const defaultValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    roleSelector: '',
  };

  const methods = useForm({
    resolver: yupResolver(membersSchema),
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const onSubmit = async (value) => {
    const data = {
      name: value.name,
      email: value.email,
      password: value.password,
      password_confirmation: value.confirmPassword,
      role_id:  memberRole?.role_id, //  (add role_id)
      
    };
    setLoading(true);
    addMember(customerId, data)
      .then(() => {
        setLoading(false);
        gridApi.purgeInfiniteCache(); 
        handleClose();
        enqueueSnackbar(
          'Member added successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  const selectRole = (value) => {
    setMemberRole(value);
  };

  // ------------------------------------------------------------------------------------

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        transition={Slide}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
            Add member
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <Close sx={{ fontSize: '1.5rem' }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          {loading && <LinearProgress />}
          <DialogContent sx={{ py: 3, px: 3 }}>
            <Stack spacing={3} sx={{ py: 1 }}>
              <RHFTextField name="name" label="Name *" placeholder="Enter your name" />
              <RHFTextField name="email" label="Email address *" placeholder="Enter email address" />

              <Stack direction="row" spacing={3}>
                <RHFTextField name="password" label="Password *" placeholder="Password" />
                <RHFTextField name="confirmPassword" label="Confirm password *" placeholder="Confirm Password" />
              </Stack>

              <MemberRoleSelector
                placeholder="Select role"
                name="roleSelector"
                roleOptions={roleOptions}
                selectRole={selectRole}
              />
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={loading}>
              Add
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}

AddMemberDialog.propTypes = {
  gridApi: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
