import React from 'react'
import {Grid} from '@mui/material';
import IPAMMainTable from './Components/IPAMMainTable';

const IPAM = () => {
    return (
        <>
        <Grid container>
          <Grid item lg={12} md={12} sm={12}>
            <IPAMMainTable />
          </Grid>
        </Grid>
        </>
      );
}

export default IPAM