import { ContentCopy, Refresh, Search, Settings, Folder } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography, useTheme, useMediaQuery, Skeleton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { WithConfirm } from '../../WithConfirm';
import { getIpam, deleteIpam } from '../../../API/IPAM/Ipam';
import { UserContext } from '../../../context/UserContext';
import { defaultAgGridProps } from '../../../utils/agGridProps';
import { getSortedColumnDef, mapColumnDefinitionToStore } from '../../../utils/columnDef';
import ColumnSettingsDialog from '../filters/ColumnSettingsDialog';
import ActionRenderer from './Renderer/ActionRenderer';
import AddIPAM from '../Components/AddIPAM';
import UploadIPAM from '../Components/UploadIPAM';


const useStyles = makeStyles(() => ({
  containerHieght: {
    height: '100%',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  cardActionButtons: {
    height: '40px'
  },
  icon: {
    visibility: 'hidden',
  },
  field: {},
  root: {
    '&:hover $field $icon': {
      visibility: 'visible',
    },
  },
  downHight: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    marginTop: 5,
  },
  progressStyle: {
    position: 'absolute',
    left: 15,
    top: 20,
  },
}));

 function IPAMTable(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm ,locationData } = props;
  const { customerId } = useContext(UserContext);
  const [gridApi, setGridApi] = useState(null);
  const [openNetworkDialog, setOpenNetworkDialog] = useState(false);
  const [openEndPointDialog, setOpenEndPointDialog] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [query, setQuery] = useState('');
  const [openColSettingsDialog, setOpenColSettingsDialog] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [ipData, setIpData] = useState(null);
  const [ipDialog, setIpDialog] = useState(false);
  const [uploadIpDialog, setUploadIpDialog] = useState(false);
  const [deleteButton, showDeleteButton] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const theme = useTheme();
  const is1024px = useMediaQuery(theme.breakpoints.only('md'));
  const is1440px = useMediaQuery('(min-width:1440px) and (max-width:2559px)');
  const is2560px = useMediaQuery('(min-width:2560px)');

  let fontSize = '12px';
  if (is1024px) {
    fontSize = '14px';
  } else if (is1440px) {
    fontSize = '16px';
  } else if (is2560px) {
    fontSize = '20px';
  }

  let fontWeight = '100';
  if (is1024px) {
    fontWeight = '200';
  } else if (is1440px) {
    fontWeight = '300';
  } else if (is2560px) {
    fontWeight = '400';
  }

  const isTablet = useMediaQuery('(max-width: 1020px)');
  const isDesktop = useMediaQuery('(min-width: 1440px)');
  const isLargeDesktop = useMediaQuery('(min-width: 2560px)');

  const getFontSize = () => {
    if (isLargeDesktop) {
      return '20px';
    }
    if (isDesktop) {
      return '16px';
    }
    if (isTablet) {
      return '14px';
    }
    return '12px';
  }

  const responsiveCellStyle = { fontSize: getFontSize(), fontFamily: 'Arial', fontWeight: '400' };



  const handleDialogClose = () => {
    setIpDialog(false);
    setIpData(null);
  };

  const handleCloseUploadIpDialog = () => {
    setUploadIpDialog(false);
    gridApi?.refreshInfiniteCache();
    setIpData(null);
  };

  const handleRefresh = () => {
    setRefreshLoading(true);
    gridApi?.refreshInfiniteCache();
    setTimeout(() => setRefreshLoading(false), 2000);
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.gridOptionsWrapper.gridOptions.context = {
        ...gridApi.gridOptionsWrapper.gridOptions.context,
        location_uuids: locationData,
      };
      gridApi.purgeInfiniteCache();
    }
  }, [locationData]);



  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem('nms-unknown-devices-column'));
    if (stored === null) {
      setColumnDefs(columnHeaders);
    } else {
      setColumnDefs(getSortedColumnDef(columnHeaders, stored));
    }
  }, []);

  // const onGridReady = useCallback((params) => {
  //   setGridApi(params.api);
  //   return params.api.setDatasource({
  //     rowCount: null,
  //     context: {
  //       global_keyword: query,
  //     },
  //     getRows: async (_params) => {
  //       setRefreshLoading(true);
  //       params.api.hideOverlay();
  //       const payload = {
  //         ..._params,
  //         global_keyword: _params.context.global_keyword,
  //         location_uuids: _params.context.location_uuids,
  //       };
  //       delete payload.context;
  //       getIpam(customerId, payload)
  //         .then((devicesList) => {

  //           let lastRow = -1;
  //           if (devicesList.total <= _params.endRow) {
  //             lastRow = devicesList.total;
  //           }

  //           setTotalItems(devicesList.length);

  //           if (devicesList.length === 0) {
  //             params.api.showNoRowsOverlay();
  //           }
  //           _params.successCallback(devicesList, lastRow);
  //           setRefreshLoading(false);
  //         })
  //         .catch((e) => {
  //           console.log(e, 'error');
  //           setRefreshLoading(false);
  //           _params.failCallback();
  //         });
  //     },
  //   });
  // }, []);
  const onGridReady = useCallback((params) => {
    setGridApi(params.api);

    params.api.setDatasource({
        rowCount: null, // Let AG Grid dynamically determine row count
        context: {
            global_keyword: query,
        },
        getRows: async (_params) => {
            setRefreshLoading(true);
            params.api.hideOverlay(); // Hide overlays while loading

            const payload = {
                ..._params,
                global_keyword: _params.context.global_keyword,
                location_uuids: _params.context.location_uuids,
            };
            delete payload.context;

            try {
                const devicesList = await getIpam(customerId, payload);

                // Determine the exact number of rows to display
                const totalRecords = devicesList.total || devicesList.length;
                const lastRow = totalRecords;

                if (totalRecords > 0) {
                    _params.successCallback(devicesList, lastRow); // Send records to the grid
                } else {
                    params.api.showNoRowsOverlay(); // Show "No Rows" overlay if no records
                    _params.successCallback([], lastRow); // Clear rows in the grid
                }

                setTotalItems(totalRecords);
            } catch (error) {
                console.error(error, 'Error fetching data');
                params.api.showNoRowsOverlay();
                _params.failCallback();
            } finally {
                setRefreshLoading(false);
            }
        },
    });
}, [query, customerId]);



  const onCopyText = (e, text) => {
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
    setTimeout(() => { }, 500);
  };

  const handleClickOpenColSettingsDialog = () => {
    setOpenColSettingsDialog(true);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    gridApi?.purgeInfiniteCache();
  };

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      floatingFilter: true,
      suppressMenu: true,
      filter: true,
    };
  }, []);

  const columnHeaders = [
    {
      headerName: 'Subnet',
      field: 'subnet_value',
      width: 250,
      checkboxSelection: true,
      cellStyle: responsiveCellStyle,
      cellRenderer: (params) => {
        if (params.data === undefined) {
          return (
            <Skeleton
              variant="rectangular"
              height={30}
              style={{ margin: "7px 0" }}
            />
          );
        }

        // Return a Tooltip and Chip component
        return (
          <Tooltip
            arrow
            title={params.data.subnet_value || "No Status"}
            key={params.data.subnet_value}
          >
            <Chip
              size="small"
              label={params.data.subnet_value}
              variant="outlined"
              style={{ margin: "5px 0" }}
            />
          </Tooltip>
        );
      },
    },
    {
      headerName: 'Location',
      field: 'location',
      width: 300,
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }

        return params.data?.location;


      },
    },
    {
      headerName: 'Region',
      field: 'region',
      width: 250,
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }

        return params.data?.region;


      },
    },
    {
      headerName: 'Remarks',
      field: 'remarks',
      width: 300,
      cellStyle: responsiveCellStyle,
      valueGetter: (params) => {
        if (params.data === undefined) {
          return '';
        }

        return params.data?.remarks;


      },
      filter: false,
    },

    {
      headerName: 'Action',
      field: 'action',
      width: 150,
      // pinned: 'right',
      cellStyle: responsiveCellStyle,
      cellRenderer: 'actionRenderer',
      filter: false,

    },
  ];

  const components = useMemo(() => ({
    actionRenderer: ActionRenderer,
  }), []);

  const _openNetworkDialog = () => {
    setSelectedData(gridApi?.getSelectedRows());
    setOpenNetworkDialog(true);
  };

  const _openEndpointDialog = () => {
    setSelectedData(gridApi?.getSelectedRows());
    setOpenEndPointDialog(true);
  };

  const onSelectionChanged = () => {
    var selectedRows = gridApi?.getSelectedRows();
    if (selectedRows.length === 0) {
      setSelectedRowCount(0);
    } else {
      setSelectedRowCount(selectedRows.length);
    }
  };

  const isExternalFilterPresent = useCallback(() => {
    return true;
  }, []);

  const resetColumnSetting = () => {
    gridApi.setColumnDefs([]);
    setColumnDefs([...columnHeaders]);
    gridApi.setColumnDefs([...columnHeaders]);
    localStorage.setItem('nms-unknown-devices-column', JSON.stringify(mapColumnDefinitionToStore(columnHeaders)));
    setOpenColSettingsDialog(false);
  };

  const onDragStopped = (params) => {
    const columnState = params.columnApi.getColumnState();
    const _afterDragColumnDefs = columnState.reduce((a, b) => {
      const a1 = columnHeaders.find((e) => e.colId === b.colId);
      return a.concat(Object.assign(a1, b));
    }, []);
    setColumnDefs([..._afterDragColumnDefs]);
    localStorage.setItem(
      'nms-unknown-devices-column',
      JSON.stringify(mapColumnDefinitionToStore(_afterDragColumnDefs))
    );
  };

  const handleCheckMultipleRows = () => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      showDeleteButton(true);
    } else {
      showDeleteButton(false);
    }
  };

  const handleMultipleDelete = () => {
    const selectedRows = gridApi.getSelectedRows();

    if (selectedRows.length === 0) {
      enqueueSnackbar(
        'Please select at least one location to delete',
        {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
        500
      );
      return;
    }
    const selectedIds = selectedRows.map((row) => row.id);
    setDeleteLoading(true);
    setRefreshLoading(true);
    Promise.all(
      selectedIds.map((id) => {
        return deleteIpam(customerId, id);
      })
    )
      .then(() => {
        enqueueSnackbar(
          'Selected locations deleted successfully',
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
        gridApi?.purgeInfiniteCache();
        gridApi?.deselectAll();
        setDeleteLoading(false);
        showDeleteButton(false);
        setRefreshLoading(false);
      })
      .catch((res) => {
        setDeleteLoading(false);
        setRefreshLoading(false);
        enqueueSnackbar(
          res.response.data.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };

  return (
    <>
      <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid container spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Chip label={totalItems || 0} />
                {refreshLoading && <CircularProgress size={30} className={classes.progressStyle} />}
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <Typography variant="h5" gutterBottom sx={{ marginLeft: '8px', marginTop: '8px' }}> IP Address Management </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid container spacing={1} sx={{ display: 'flex', flexDirection: "row", justifyContent: 'right', alignItems: 'center', paddingTop: '10px' }}>
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                {deleteButton ? (
                  <Tooltip title="Delete">
                    <LoadingButton
                      className={classes.cardActionButtons}
                      onClick={confirm(() => handleMultipleDelete(), {
                        title: 'Information',
                        confirmationText: 'Yes',
                        disableOkBtn: false,
                        description: 'Are you sure you want to delete selected Locations',
                      })}
                      loading={deleteLoading}
                      variant="outlined"
                    >
                      Delete
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  ''
                )}
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  onClick={() => setUploadIpDialog(true)}
                  color="primary"
                  sx={{ height: '40px' }}
                  variant="outlined"
                  startIcon={<Folder />}
                >
                  Import file
                </Button>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  onClick={() => setIpDialog(true)}
                  color="primary"
                  sx={{ height: '40px' }}
                  variant="outlined"
                >
                  Add IP Address
                </Button>
              </Grid>
              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search devices"
                />
              </Grid>


              <Grid item xs="auto" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}>
                <IconButton color="primary" onClick={handleRefresh}>
                  {refreshLoading ? (
                    <CircularProgress size={27} sx={{ mr: '5px' }} />
                  ) : (
                    <Refresh sx={{ fontSize: '2rem' }} />
                  )}
                </IconButton>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Box>
                  <Tooltip title="Column settings">
                    <IconButton color="primary" onClick={handleClickOpenColSettingsDialog}>
                      <Settings sx={{ fontSize: '2rem' }} />
                    </IconButton>
                  </Tooltip>
                  {openColSettingsDialog && (
                    <ColumnSettingsDialog
                      gridApi={gridApi}
                      columnDefs={columnDefs}
                      openDialog={openColSettingsDialog}
                      resetColumnSetting={resetColumnSetting}
                      handleClose={() => setOpenColSettingsDialog(false)}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          }
        />

        <Divider />
        {refreshLoading && <LinearProgress />}

        <Box style={{ height: '500px', width: '100%' }} className="ag-theme-material">
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            onDragStopped={onDragStopped}
            defaultColDef={defaultColDef}
            components={components}
            onSelectionChanged={() => handleCheckMultipleRows()}
            isExternalFilterPresent={isExternalFilterPresent}
            {...defaultAgGridProps}
            context={{
              global_keyword: query,
              location_uuids: locationData,
            }}
          />
        </Box>
      </Card>
      {uploadIpDialog && (
        <UploadIPAM
          openDialog={uploadIpDialog}
          handleClose={handleCloseUploadIpDialog}
          gridApi={gridApi}
        />
      )}
      {ipDialog && (
        <AddIPAM
          openDialog={ipDialog}
          handleClose={handleDialogClose}
          gridApi={gridApi}
        />
      )}
    </>
  );
}
export default WithConfirm(IPAMTable);

IPAMTable.propTypes = {
  confirm: PropTypes.func.isRequired,
};