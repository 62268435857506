import { Folder ,Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, Divider,
  Grid, Paper, Typography,TextField,InputAdornment
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import { WithConfirm } from '../../WithConfirm';
import APITable from '../Table/APITable';
import SNMPTable from '../Table/SNMPTable';
import IPAMTable from '../Table/IPAMTable';
import SSHTable from '../Table/SSHTable';
import AddCredentials from './AddCredentials';
import AddIPAM from './AddIPAM';
import UploadCredential from './UploadCredential';
import UploadIPAM from './UploadIPAM';

// -----------------------------------------------------------------------------
const IPAMMainTable = (props) => {
  const [ipData, setIpData] = useState(null);
  const [ipDialog, setIpDialog] = useState(false);
  const [uploadIpDialog, setUploadIpDialog] = useState(false);
  const [value, setValue] = useState(0);
  const [query, setQuery] = useState('');


  const handleDialogClose = () => {
    setIpDialog(false);
    setIpData(null);
  };

  const handleCloseUploadIpDialog = () => {
    setUploadIpDialog(false);
    setIpData(null);
  };
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    // gridApi?.purgeInfiniteCache();
  };

  // ----------------------------------------------------------------------------------------------

  return (
    <>
      {/* <Card>
        <CardHeader
          style={{ padding: '10px' }}
          avatar={
            <Grid
              container
              spacing={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Typography variant="h6" gutterBottom sx={{ ml: 1, marginTop: '8px' }}>
                  {' '}
                  IP Address Management{' '}
                </Typography>
              </Grid>
            </Grid>
          }
          action={
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                paddingTop: '10px',
              }}
            >
              <TextField
                  focused
                  autoFocus
                  type="text"
                  size="small"
                  value={query}
                  variant="outlined"
                  name='new-password'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleQueryChange}
                  placeholder="Search devices"
                />

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  onClick={() => setUploadIpDialog(true)}
                  color="primary"
                  sx={{ height: '40px' }}
                  variant="outlined"
                  startIcon={<Folder />}
                >
                  Import file
                </Button>
              </Grid>

              <Grid
                item
                xs="auto"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1px' }}
              >
                <Button
                  onClick={() => setIpDialog(true)}
                  color="primary"
                  sx={{ height: '40px' }}
                  variant="outlined"
                >
                  Add IP Address
                </Button>
              </Grid>

            </Grid>
          }
        />

        <Divider />
        <CardContent style={{ padding: '0px' }}>
          <Paper style={{ height: '646px' }}> */}
            <IPAMTable />

            {/* {uploadIpDialog && (
              <UploadIPAM
                openDialog={uploadIpDialog}
                handleClose={handleCloseUploadIpDialog}
              />
            )}
            {ipDialog && (
              <AddIPAM
                openDialog={ipDialog}
                handleClose={handleDialogClose}
              />
            )}

          </Paper>
        </CardContent>
      </Card> */}
    </>
  );
};
export default WithConfirm(IPAMMainTable);



