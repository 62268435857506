import { TestbedInstance, instance as modem } from '../modem';

const id = 1;
// export const getWifiEvents = async (id, data) => {
//   const testbedModem = TestbedInstance();
//   const newPromise =  modem
//     .post(`${id}/inventory/unknown`, data)
//     .then((response) => {
//       return response.data.data;
//     })
//     return newPromise
// };

export const getWifiEvents = async () => {

  const hardcodedData = {
    data: {
      current_page: 1,
      data:
        [
          {
            'user_name': 'Sahana M',
            'mac_addr': 'a0:29:42:22:fb:32',
            'ap_name': 'Open-Area-2F',
            'location': 'Bangalore HQ',
            'ssid': 'Secure',
            'signal_strength': '56',
            'ip_addr': '0.0.0.0',
            'co_pilot_summary': 'Client Authentication is Successful,But Client is unable to Get an IP Address. The Role configuration in the AccessPoint has DHCP allowed . Check VLAN Configuration on the Transient Switches or Check the DHCP server Lease',
            'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model.Checking Client Association Table.Checking Client Authentication Methodology.Checking Radius Server Reachability from AccessPoint.Checking Client Authentication Status.Checking Client SNR. Checking User Firewall/Role  Policies. Checking Client VLAN Configuration on SSID.Checking IP Address Assignment',
            'rca_data': ''
          },
          {
            'user_name': 'Sachin Gupta',
            'mac_addr': '1e:a6:22:72:be:c7',
            'ap_name': '4F-3B-AP',
            'location': 'Delhi', 'ssid':
              'Secure', 'signal_strength': '72',
            'ip_addr': '0.0.0.0',
            'co_pilot_summary': 'Client Association was successful But Dot1x Authentication failed . External Radius Server is configured . Please check the Logs in the External Radius Server.',
            'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model.Checking Client Association Table.Checking Client Authentication Methodology.Checking Radius Server Reachability from AccessPoint.Checking Client Authentication Status',
            'rca_data': ''
          },
          {
            'user_name': 'Charles',
            'mac_addr': '28:de:65:b1:a3:25',
            'ap_name': 'FF-25-AP',
            'location': 'Chennai',
            'ssid': 'Secure',
            'signal_strength': '65',
            'ip_addr': '0.0.0.0',
            'co_pilot_summary': 'Client Association failed due to Maximum client Thresold reached on the AccessPoint .Check the Status of Other AccessPoints in vicinity or increase the AP count.',
            'steps': 'Checking the SSID Configuration of the Wireless Network.Verifying  SSID Broadcast.Checking Current AP Capacity by  Model',
            'rca_data': ''
          }
        ]
    }
  }
  return Promise.resolve(hardcodedData.data.data);
}

export const getWirelessTroubleshoot = async (customerId, data) => {
  const newPromise =  modem
    .post(`${customerId}/co-pilot/wi-fi/all`, data)
    .then((response) => {

      return response.data;
    })
    return newPromise

  // const datas = {
  //   "current_page": 1,
  //   "data": [
  //     // {
  //     //   "id": 1,
  //     //   "client_mac_address": "00:1A:2B:3C:4D:5E",
  //     //   "troubleshoot_result":
  //     //     [
  //     //       // {
  //     //       //   "client_mac_address": "c4:75:ab:14:76:cf",
  //     //       //   "client_name": "Sahana",
  //     //       //   "connected_ap_name": "HEHEHAHAHAHAHA",
  //     //       //   "connected_ap_ip": "10.45.67.89",
  //     //       //   "location_name": "Pakisthan DC",
  //     //       //   "SSID_name": "Jai-Shree-Ram",
  //     //       //   "Signal_strength": "56",
  //     //       //   "client_ip_address": "10.56.7.3",
  //     //       //   "co_pilot_summary": "Everything's Fucked UP, call LinkEye",
  //     //       //   "co_pilot_last_updated": "2024-11-21 11:18:56",
  //     //       //   "co-pilot_rca": "Jai shree ram",
  //     //       //   "rca_code":"0",
  //     //       //   "copilot_insights": [
  //     //       //     {
  //     //       //       "step": 1,
  //     //       //       "code": 1,
  //     //       //       "troubleshoot_step": "Checking Association entry for the client",
  //     //       //       "troubleshoot_msg": "Association entry found in station table with AP 10.25.33.6.",
  //     //       //       "troubleshoot_detailed_msg": ""
  //     //       //     },
  //     //       //     {
  //     //       //       "step": 2,
  //     //       //       "code": 0,
  //     //       //       "troubleshoot_step": "Checking SSID configuration for the client",
  //     //       //       "troubleshoot_msg": "SSID Configuration is good on the wireless network.",
  //     //       //       "troubleshoot_detailed_msg": "sample"
  //     //       //     },
  //     //       //     {
  //     //       //       "step": 3,
  //     //       //       "code": null,
  //     //       //       "troubleshoot_step": "Verifying SSID Broadcast from AP",
  //     //       //       "troubleshoot_msg": "",
  //     //       //       "troubleshoot_detailed_msg": ""
                
  //     //       //     },
  //     //       //     {
  //     //       //       "step": 4,
  //     //       //      "code": null,
  //     //       //       "troubleshoot_step": "Checking Client Association entry",
  //     //       //       "troubleshoot_msg": " ",
  //     //       //       "troubleshoot_detailed_msg": ""
  //     //       //     },
  //     //       //     // {
  //     //       //     //   "step": 4.1,
  //     //       //     //   "code": 2,
  //     //       //     //   "troubleshoot_step": "Checking Authentication Server reachability",
  //     //       //     //   "troubleshoot_msg": "The following authentication server/s are not reachable: 10.27.40.173 from the controller",
  //     //       //     //   "troubleshoot_detailed_msg": "jai shree ram"
  //     //       //     // },
  //     //       //     {
  //     //       //       "step": 5,
  //     //       //      "code": null,
  //     //       //       "troubleshoot_step": "Checking Client IP Address Assignment",
  //     //       //       "troubleshoot_msg": "",
  //     //       //       "troubleshoot_detailed_msg": ""
  //     //       //     },
  //     //       //     {
  //     //       //       "step": 6,
  //     //       //      "code": null,
  //     //       //       "troubleshoot_step": "Checking Client SNR value",
  //     //       //       "troubleshoot_msg": " ",
  //     //       //       "troubleshoot_detailed_msg": ""
  //     //       //     },
  //     //       //     // {
  //     //       //     //   "step": 7,
  //     //       //     //   "code": 1,
  //     //       //     //   "troubleshoot_step": "Checking Client Role Policies",
  //     //       //     //   "troubleshoot_msg": "All the policies are in place for internet traffic to flow for client's role.",
  //     //       //     //   "troubleshoot_detailed_msg": ""
  //     //       //     // },
  //     //       //     // {
  //     //       //     //   "step": 8,
  //     //       //     //   "code": 1,
  //     //       //     //   "troubleshoot_step": "Verifying traffic flow for the client",
  //     //       //     //   "troubleshoot_msg": "The client has HTTPS traffic flowing, confirming that the internet is working for the client.",
  //     //       //     //   "troubleshoot_detailed_msg": ""
  //     //       //     // }
                
  //     //       //   ]
  //     //       // }
  //     //       {
  //     //         "client_mac_address": "90:9:df:bb:c8:a3",
  //     //         "client_name": "9009dfbbc8a3_IPRINT01_%{Authorization:[Guest",
  //     //         "connected_ap_name": "N/A",
  //     //         "connected_ap_ip": "N/A",
  //     //         "location_name": "Pakisthan DC",
  //     //         "SSID_name": "N/A",
  //     //         "Signal_strength": "N/A",
  //     //         "client_ip_address": "N/A",
  //     //         "co_pilot_summary": "Everything is Fucked UP, call LinkEye",
  //     //         "co_pilot_rca_code": 1,
  //     //         "co_pilot_rca": "",
  //     //         "copilot_insights": [
  //     //             {
  //     //                 "step": 1,
  //     //                 "code": 0,
  //     //                 "troubleshoot_step": "Verifying the association table entry for the client.",
  //     //                 "troubleshoot_msg": "No association table entry found for the client: 90:9:df:bb:c8:a3.",
  //     //                 "troubleshoot_detailed_msg": "No association entry was found for the MAC address: 90:9:df:bb:c8:a3. \nPlease ensure that the MAC address is correct and try again."
  //     //             },
  //     //             {
  //     //                 "step": 2,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "Verifying the SSID configuration for the client.",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             },
  //     //             {
  //     //                 "step": 3,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "Checking SSID broadcast status.",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             },
  //     //             {
  //     //                 "step": 4,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             },
  //     //             {
  //     //                 "step": 4.1,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             },
  //     //             {
  //     //                 "step": 5,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             },
  //     //             {
  //     //                 "step": 6,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             },
  //     //             {
  //     //                 "step": 7,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             },
  //     //             {
  //     //                 "step": 8,
  //     //                 "code": null,
  //     //                 "troubleshoot_step": "",
  //     //                 "troubleshoot_msg": "",
  //     //                 "troubleshoot_detailed_msg": ""
  //     //             }
  //     //         ]
  //     //     }
  //     //     ],
  //     //   "troubleshoot_requested_at": "2024-11-20 17:29:43",
  //     //   "troubleshoot_completed_at": "2024-11-20 17:29:43",
  //     //   "troubleshoot_status": 1
  //     // },
  //     // {
  //     //   "id": 2,
  //     //   "client_mac_address": "55:44:33:22:11:00",
  //     //   // "troubleshoot_result": null,
  //     //   // [
  //     //   //   {
  //     //   //     "client_mac_address": "c4:75:ab:14:76:cf",
  //     //   //     "client_name": "Sahana",
  //     //   //     "connected_ap_name": "HEHEHAHAHAHAHA",
  //     //   //     "connected_ap_ip": "10.45.67.89",
  //     //   //     "location_name": "Pakisthan DC",
  //     //   //     "SSID_name": "Jai-Shree-Ram",
  //     //   //     "Signal_strength": "56",
  //     //   //     "client_ip_address": "10.56.7.3",
  //     //   //     "co_pilot_summary": "Everything's Fucked UP, call LinkEye",
  //     //   //     "co_pilot_last_updated": "2024-11-21 11:18:56",
  //     //   //     "co-pilot_rca": "Jai shree ram",
  //     //   //     "copilot_insights": [
  //     //   //       {
  //     //   //         "step": 1,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Checking Association entry for the client",
  //     //   //         "troubleshoot_msg": "Association entry found in station table with AP 10.25.33.6.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 2,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Checking SSID configuration for the client",
  //     //   //         "troubleshoot_msg": "SSID Configuration is good on the wireless network.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 3,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Verifying SSID Broadcast from AP",
  //     //   //         "troubleshoot_msg": "AP is broadcasting the SSID properly.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 4,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Checking Client Association entry",
  //     //   //         "troubleshoot_msg": "Client Association entry found.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 4.1,
  //     //   //         "code": 2,
  //     //   //         "troubleshoot_step": "Checking Authentication Server reachability",
  //     //   //         "troubleshoot_msg": "The following authentication server/s are not reachable: 10.27.40.173 from the controller",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 5,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Checking Client IP Address Assignment",
  //     //   //         "troubleshoot_msg": "Client is using the IP 10.25.33.6 obtained via DHCP.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 6,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Checking Client SNR value",
  //     //   //         "troubleshoot_msg": "SNR is excellent for the client.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 7,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Checking Client Role Policies",
  //     //   //         "troubleshoot_msg": "All the policies are in place for internet traffic to flow for client's role.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       },
  //     //   //       {
  //     //   //         "step": 8,
  //     //   //         "code": 1,
  //     //   //         "troubleshoot_step": "Verifying traffic flow for the client",
  //     //   //         "troubleshoot_msg": "The client has HTTPS traffic flowing, confirming that the internet is working for the client.",
  //     //   //         "troubleshoot_detailed_msg": ""
  //     //   //       }
  //     //   //     ]
  //     //   //   }
  //     //   // ],
  //     //   "troubleshoot_requested_at": "2024-11-20 17:33:33",
  //     //   "troubleshoot_completed_at": "2024-11-20 17:33:33",
  //     //   "troubleshoot_status": 1
  //     // }
  //     {
  //       "id": 9,
  //       "client_mac_address": "42:ab:90:7d:8c:70",
  //       "troubleshoot_result": null,
  //       "troubleshoot_requested_at": "2024-11-21 19:33:21",
  //       "troubleshoot_completed_at": "2024-11-21 17:56:46",
  //       "troubleshoot_status": 0
  //   },
  //   {
  //       "id": 13,
  //       "client_mac_address": "54:6c:eb:d8:eb:63",
  //       "troubleshoot_result": [
  //           {
  //               "SSID_name": "DK-CORP",
  //               "client_name": "VHR07",
  //               "co_pilot_rca": "jai shree Ram",
  //               "location_name": "Pakisthan DC",
  //               "Signal_strength": 27,
  //               "connected_ap_ip": "10.25.32.128",
  //               "co_pilot_summary": "Everything is Fucked UP, call LinkEye",
  //               "copilot_insights": [
  //                   {
  //                       "code": 1,
  //                       "step": 1,
  //                       "troubleshoot_msg": "Association table entry found for the client: 54:6c:eb:d8:eb:63.",
  //                       "troubleshoot_step": "Verifying the association table entry for the client.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 1,
  //                       "step": 2,
  //                       "troubleshoot_msg": "Configurations are correctly set for the SSID: DK-CORP. The allowed bands for this SSID are: 802.11a and 802.11b/g bands (5 GHz and 2.4 GHz), and the maximum number of supported clients are 64.",
  //                       "troubleshoot_step": "Verifying the SSID configuration for the client.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 1,
  //                       "step": 3,
  //                       "troubleshoot_msg": "The SSID is being broadcast by the client AP: 10.25.32.128. The uptime of the AP is 23d:13h:1m:34s, and the total number of currently connected clients are 3.",
  //                       "troubleshoot_step": "Checking SSID broadcast status.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 1,
  //                       "step": 4,
  //                       "troubleshoot_msg": "Authentication table entry found for the client: 54:6c:eb:d8:eb:63. The client authentication method in use is 802.1x(EAP-TLS). The client has performed a total of 0 reauthentications so far.",
  //                       "troubleshoot_step": "Verifying client authentication status.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 1,
  //                       "step": 4.1,
  //                       "troubleshoot_msg": "All authentication servers are reachable from the controller, and the current server being used by the client for authentication is INSECUAIR01.",
  //                       "troubleshoot_step": "Checking the reachability of the client authentication server.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 1,
  //                       "step": 5,
  //                       "troubleshoot_msg": "The client is using the IP address 10.25.32.128, obtained through DHCP.",
  //                       "troubleshoot_step": "Checking client IP allocation.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 1,
  //                       "step": 6,
  //                       "troubleshoot_msg": "SNR for the client is good.",
  //                       "troubleshoot_step": "Checking the client SNR value.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 1,
  //                       "step": 7,
  //                       "troubleshoot_msg": "All necessary policies are in place to enable internet traffic flow for the client role.",
  //                       "troubleshoot_step": "Verifying the client role policies and configuration.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": 2,
  //                       "step": 8,
  //                       "troubleshoot_msg": "Traffic is detected, but no explicit HTTPS or internet-related traffic is observed. Please verify the client internet access settings.",
  //                       "troubleshoot_step": "Checking the client internet traffic flow.",
  //                       "troubleshoot_detailed_msg": null
  //                   }
  //               ],
  //               "client_ip_address": "10.25.32.128",
  //               "co_pilot_rca_code": 1,
  //               "connected_ap_name": "INCAC-C04-M-AP04-AR13-8B18"
  //           }
  //       ],
  //       "troubleshoot_requested_at": "2024-11-21 18:53:47",
  //       "troubleshoot_completed_at": "2024-11-22 18:54:14",
  //       "troubleshoot_status": 1
  //   },
  //   {
  //       "id": 14,
  //       "client_mac_address": "90:9:df:bb:c8:a3",
  //       "troubleshoot_result": [
  //           {
  //               "SSID_name": "N/A",
  //               "client_name": "9009dfbbc8a3_IPRINT01_%{Authorization:[Guest",
  //               "co_pilot_rca": null,
  //               "location_name": "Pakisthan DC",
  //               "Signal_strength": "N/A",
  //               "connected_ap_ip": "N/A",
  //               "co_pilot_summary": "Everything is Fucked UP, call LinkEye",
  //               "copilot_insights": [
  //                   {
  //                       "code": 0,
  //                       "step": 1,
  //                       "troubleshoot_msg": "No association table entry found for the client: 90:9:df:bb:c8:a3.",
  //                       "troubleshoot_step": "Verifying the association table entry for the client.",
  //                       "troubleshoot_detailed_msg": "No association entry was found for the MAC address: 90:9:df:bb:c8:a3. \nPlease ensure that the MAC address is correct and try again."
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 2,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying the SSID configuration for the client.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 3,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Checking SSID broadcast status.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 4,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying client authentication status.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 4.1,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying the client authentication server reachability.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 5,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Checking client IP allocation.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 6,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Checking the client SNR value.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 7,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying the client role policies and configuration.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 8,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying the client role policies and configuration.",
  //                       "troubleshoot_detailed_msg": null
  //                   }
  //               ],
  //               "client_ip_address": "N/A",
  //               "co_pilot_rca_code": 1,
  //               "connected_ap_name": "N/A"
  //           }
  //       ],
  //       "troubleshoot_requested_at": "2024-11-21 19:32:45",
  //       "troubleshoot_completed_at": "2024-11-22 19:14:12",
  //       "troubleshoot_status": 1
  //   },
  //   {
  //       "id": 15,
  //       "client_mac_address": "7c:78:7e:92:2a:c8",
  //       "troubleshoot_result": [
  //           {
  //               "SSID_name": "N/A",
  //               "client_name": "7c787e922ac8",
  //               "co_pilot_rca": null,
  //               "location_name": "Pakisthan DC",
  //               "Signal_strength": "N/A",
  //               "connected_ap_ip": "N/A",
  //               "co_pilot_summary": "Everything is Fucked UP, call LinkEye",
  //               "copilot_insights": [
  //                   {
  //                       "code": 0,
  //                       "step": 1,
  //                       "troubleshoot_msg": "No association table entry found for the client: 7c:78:7e:92:2a:c8.",
  //                       "troubleshoot_step": "Verifying the association table entry for the client.",
  //                       "troubleshoot_detailed_msg": "No association entry was found for the MAC address: 7c:78:7e:92:2a:c8. \nPlease ensure that the MAC address is correct and try again."
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 2,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying the SSID configuration for the client.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 3,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Checking SSID broadcast status.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 4,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying client authentication status.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 5,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Checking client IP allocation.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 6,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Checking the client SNR value.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 7,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying the client role policies and configuration.",
  //                       "troubleshoot_detailed_msg": null
  //                   },
  //                   {
  //                       "code": null,
  //                       "step": 8,
  //                       "troubleshoot_msg": null,
  //                       "troubleshoot_step": "Verifying the client role policies and configuration.",
  //                       "troubleshoot_detailed_msg": null
  //                   }
  //               ],
  //               "client_ip_address": "N/A",
  //               "co_pilot_rca_code": 1,
  //               "connected_ap_name": "N/A"
  //           }
  //       ],
  //       "troubleshoot_requested_at": "2024-11-21 19:33:08",
  //       "troubleshoot_completed_at": "2024-11-22 19:14:15",
  //       "troubleshoot_status": 1
  //   }
  //   ],
  //   "total": 4
  // }
  // return datas

}

export const getWanCopilotEvents = async () => {

  const hardcodedData = {
    data: {
      current_page: 1,
      data:
        [
          {
            "uid": 18035,
            "isp_wan_id": 11319,
            "client_id": 110,
            "public_ip": "103.182.158.60",
            "good_cnt": 0,
            "bad_cnt": 0,
            "up_cnt": 0,
            "down_cnt": 579,
            "ent": "2024-11-06 17:05:43",
            "info_id": 718956083,
            "circuit_id": "TITAN_SATARA_IN_RTR_YTD",
            "min_rtt": 0,
            "avg_rtt": 0,
            "max_rtt": 0,
            "jitter": 0,
            "packet_loss": 100,
            "status": 0,
            "dg_reach_status": 1,
            "cust_id": 110,
            "bu_id": 12,
            "zone_id": 193,
            "group_id": 198,
            "ext_probe_id": -1,
            "device_id": 1178,
            "location_id": 2870,
            "location": "PIT-Tanishq Store",
            "private_ip": null,
            "internal_ip": "192.168.0.57",
            "vendor_id": 3097,
            "vendor": "SB NETWORK",
            "vendor_email": "support@sbnetworkbd.com",
            "default_gateway": "0.0.0.0",
            "firewall_ip": "10.130.126.1",
            "promised_uptime": 99,
            "resolution_time": 4,
            "business_hr_start": 10,
            "business_hr_end": 18,
            "link_type": "DBB",
            "provisioned_speed": 200,
            "price": 1000,
            "city": "Mumbai",
            "pause_status": 0,
            "util_alert_status": 1,
            "util_warn_th": 80,
            "util_critical_th": 100,
            "renewal_date": "2024-01-01",
            "if_name": "ge0/2",
            "if_index": 3,
            "tag_values": null,
            "created_date": "2024-06-13 11:01:31",
            "cust_name": "Titan",
            "ed_vendor_id": 13,
            "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
          },
          {
            "uid": 16293,
            "isp_wan_id": 19495,
            "client_id": 110,
            "public_ip": "180.151.14.129",
            "good_cnt": 0,
            "bad_cnt": 0,
            "up_cnt": 0,
            "down_cnt": 1014,
            "ent": "2024-11-08 10:46:16",
            "info_id": 718955803,
            "circuit_id": "PATPARGANJ-CFA",
            "min_rtt": 0,
            "avg_rtt": 0,
            "max_rtt": 0,
            "jitter": 0,
            "packet_loss": 100,
            "status": 0,
            "dg_reach_status": 0,
            "cust_id": 110,
            "bu_id": 11,
            "zone_id": 193,
            "group_id": 208,
            "ext_probe_id": 11,
            "device_id": 0,
            "location_id": 4483,
            "location": "Patparganj-CFA",
            "private_ip": null,
            "internal_ip": "180.151.14.129",
            "vendor_id": 2944,
            "vendor": "TCL",
            "vendor_email": "corp.helpdesk@tatacommunications.com",
            "default_gateway": "180.151.14.130",
            "firewall_ip": null,
            "promised_uptime": 99,
            "resolution_time": 4,
            "business_hr_start": 9,
            "business_hr_end": 18,
            "link_type": "ILL",
            "provisioned_speed": 100,
            "price": 140000,
            "city": "East Delhi",
            "pause_status": 0,
            "util_alert_status": 1,
            "util_warn_th": 80,
            "util_critical_th": 100,
            "renewal_date": "2025-01-01",
            "if_name": null,
            "if_index": null,
            "tag_values": null,
            "created_date": "2024-10-03 11:40:35",
            "cust_name": "Titan",
            "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
          },
          {
            "uid": 18085,
            "isp_wan_id": 19525,
            "client_id": 110,
            "public_ip": "106.219.123.235",
            "good_cnt": 0,
            "bad_cnt": 0,
            "up_cnt": 0,
            "down_cnt": 6192,
            "ent": "2024-11-08 10:46:16",
            "info_id": 718956238,
            "circuit_id": "Faridabad_Fragrance-CFA_Unknown",
            "min_rtt": 0,
            "avg_rtt": 0,
            "max_rtt": 0,
            "jitter": 0,
            "packet_loss": 100,
            "status": 0,
            "dg_reach_status": 1,
            "cust_id": 110,
            "bu_id": 11,
            "zone_id": 193,
            "group_id": 208,
            "ext_probe_id": -1,
            "device_id": 1888,
            "location_id": 4480,
            "location": "Faridabad_Fragrance-CFA",
            "private_ip": null,
            "internal_ip": "192.168.1.3",
            "vendor_id": 2601,
            "vendor": "AIRTEL",
            "vendor_email": "esupport@in.airtel.com",
            "default_gateway": "192.168.1.3",
            "firewall_ip": "172.28.22.1",
            "promised_uptime": 99,
            "resolution_time": 4,
            "business_hr_start": 9,
            "business_hr_end": 18,
            "link_type": "DBB",
            "provisioned_speed": 200,
            "price": 0,
            "city": "Faridabad",
            "pause_status": 0,
            "util_alert_status": 1,
            "util_warn_th": 80,
            "util_critical_th": 100,
            "renewal_date": "1970-01-01",
            "if_name": "wan1",
            "if_index": 9,
            "tag_values": null,
            "created_date": "2024-10-04 13:31:24",
            "cust_name": "Titan",
            "ed_vendor_id": 12,
            "troubleshoot_action": "All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.\nPlease reach out to the service provider for resolution."
          }
        ]
    }

  };

  return Promise.resolve(hardcodedData.data.data);
}


export const addMacAddress = async (customerId, data) => {
  const newPromise = modem
    .post(`${customerId}/co-pilot/wi-fi`, data)
    .then((response) => {
      return response.data;
    })
  return newPromise
}