// import React, { useState, useEffect } from 'react';
// // @mui
// import CloseIcon from '@mui/icons-material/Close';
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from '@mui/icons-material/Clear';
// import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

// import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Radio, RadioGroup, FormControlLabel, FormControl, TextField, InputLabel, Select, MenuItem, Checkbox, Divider, Typography } from '@mui/material';


// export default function WifiTroubleshootInsightsDialog(props) {

//     const { value, openInsightDialog, setOpenInsightDialog } = props;
//     const [infoList, setInfoList] = useState(value.steps);

//     const [rcaData, setRcaData] = useState(value.co_pilot_summary);
//     const [count, setCount] = useState(0);
//     const [open, setOpen] = useState(false);
//     const [detailedMsg, setDetailedMsg] = useState("");

//     const handleOpenModal = (message) => {
//         setDetailedMsg(message);
//         setOpen(true);
//     };

//     const handleCloseModal = () => {
//         setOpen(false);
//         setDetailedMsg("");
//     };

//     const formatAsBullets = (message) => {
//         if (!message) return null;

//         // Split the message by newline and wrap each line in a bullet point
//         const bulletPoints = message.split('\n').map((line, index) => `• ${line.trim()}`);

//         // Join the points with newlines for modal display
//         return bulletPoints.join('\n');
//     };

//     return (
//         <>
//             <Dialog open={openInsightDialog} onClose={() => { setOpenInsightDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
//                 <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
//                     Wireless TroubleShoot
//                     <IconButton onClick={() => { setOpenInsightDialog(false) }} sx={{ float: 'right' }}>
//                         <CloseIcon sx={{ fontSize: '1.5rem' }} />
//                     </IconButton>
//                 </DialogTitle>
//                 <Divider />
//                 <DialogContent sx={{ py: 2, px: 3 }}>
//                     <List>
//                         {value.troubleshoot_result?.[0]?.copilot_insights?.map((insight, index) => {
//                             let mainStepCounter = 0
//                             const isSubStep = insight.step.toString().includes(".");
//                             return (
//                                 <React.Fragment key={index}>
//                                     <ListItemButton sx={{ pl: isSubStep ? 2 : 2 }}>
//                                         <ListItemIcon key={index}>
//                                             {isSubStep ? (
//                                                 <ChevronRightIcon />
//                                             ) : (
//                                                 <span>{index + 1}.</span> 

//                                             )}
//                                         </ListItemIcon>
//                                         <Box sx={{ display: "flex", alignItems: "center", gap: 1, flex: 1 }}>
//                                             <ListItemText
//                                                 primary={insight.troubleshoot_step}
//                                                 sx={{ fontWeight: isSubStep ? "normal" : "bold", flex: 2, marginLeft: '-13px' }}
//                                             />
//                                             <Box sx={{ flex: 1, display: "flex", flexDirection: "column", marginLeft: "-180px" }}>
//                                                 <Box sx={{ display: "flex", alignItems: "center" }}>

//                                                     {insight.code === 1 ? (
//                                                         <CheckIcon style={{ color: "green" }} />
//                                                     ) : insight.code === 0 ? (
//                                                         <ClearIcon style={{ color: "red" }} />
//                                                     ) : insight.code === 2 ? (
//                                                         <PriorityHighIcon style={{ color: "orange" }} />
//                                                     ) : null}
//                                                     <Typography variant="body2" sx={{ ml: 1 }}>
//                                                         {insight.troubleshoot_msg}
//                                                     </Typography>

//                                                 </Box>
//                                                 {(insight.code === 0) && (
//                                                     <Typography
//                                                         variant="body2"
//                                                         sx={{
//                                                             color: "blue",
//                                                             textDecoration: "underline",
//                                                             cursor: "pointer",
//                                                             mt: 1,
//                                                             textAlign: "center",
//                                                         }}
//                                                         onClick={() => handleOpenModal(insight.troubleshoot_detailed_msg)}
//                                                     >
//                                                         Click here for more details
//                                                     </Typography>
//                                                 )}




//                                             </Box>
//                                         </Box>
//                                     </ListItemButton>
//                                     {index !== value.troubleshoot_result[0].copilot_insights.length - 1 && <Divider />}
//                                 </React.Fragment>
//                             );
//                         })}
//                         <ListItemText
//                             primary={value.troubleshoot_result?.[0]?.["co_pilot_rca"]}
//                             sx={{
//                                 color: value.troubleshoot_result?.[0]?.co_pilot_rca_code === 0 ? "red" : value.troubleshoot_result?.[0]?.co_pilot_rca_code === 1 ? "green" : "black",
//                                 fontWeight: 1000,
//                                 textAlign: "center",
//                                 mt: 4,
//                             }}
//                         />
//                     </List>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button variant="contained" onClick={() => { setOpenInsightDialog(false) }}>OK</Button>
//                 </DialogActions>
//             </Dialog>

//             <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="sm">
//                 <DialogTitle>Detailed Information</DialogTitle>
//                 <DialogContent>
//                     <Typography variant="body1">{detailedMsg}</Typography>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleCloseModal} color="primary">
//                         Close
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </>
//     );
// }


import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Divider, Typography } from '@mui/material';

export default function WifiTroubleshootInsightsDialog(props) {
    const { value, openInsightDialog, setOpenInsightDialog } = props;
    const [open, setOpen] = useState(false);
    const [detailedMsg, setDetailedMsg] = useState("");

    const handleOpenModal = (message) => {
        setDetailedMsg(message);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        setDetailedMsg("");
    };

    return (
        <>
            <Dialog
                open={openInsightDialog}
                onClose={() => setOpenInsightDialog(false)}
                fullWidth
                maxWidth="lg"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Wireless Troubleshoot
                    <IconButton
                        onClick={() => setOpenInsightDialog(false)}
                        sx={{ float: 'right' }}
                    >
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 2, px: 3 }}>
                    <List>
                        {/* Counter for main steps */}
                        {(() => {
                            let mainStepCounter = 0;
                            return value.troubleshoot_result?.[0]?.copilot_insights?.map((insight, index) => {
                                const isSubStep = insight.step.toString().includes(".");
                                if (!isSubStep) mainStepCounter++;
                                return (
                                    <React.Fragment key={index}>
                                        <ListItemButton sx={{ pl: isSubStep ? 2 : 2 }}>
                                            <ListItemIcon>
                                                {isSubStep ? (
                                                    <ChevronRightIcon />
                                                ) : (
                                                    <Typography>{mainStepCounter}.</Typography>
                                                )}
                                            </ListItemIcon>
                                            <Box sx={{ display: "flex", alignItems: "center", gap: 1, flex: 1 }}>
                                                <ListItemText
                                                    primary={insight.troubleshoot_step}
                                                    sx={{
                                                        fontWeight: isSubStep ? "100" : "bold",
                                                        fontSize: isSubStep ? "0.85rem" : "1rem", // Smaller font size for sub-steps
                                                        flex: 2,
                                                        marginLeft: isSubStep ? 0 : 0,
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        flex: 1,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginLeft: isSubStep ? 0 : 0,
                                                    }}
                                                >
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        {insight.code === 1 ? (
                                                            <CheckIcon style={{ color: "green" }} />
                                                        ) : insight.code === 0 ? (
                                                            <ClearIcon style={{ color: "red" }} />
                                                        ) : insight.code === 2 ? (
                                                            <PriorityHighIcon style={{ color: "orange" }} />
                                                        ) : null}
                                                        <Typography variant="body2" sx={{ ml: 1 }}>
                                                            {insight.troubleshoot_msg}
                                                        </Typography>
                                                    </Box>
                                                    {insight.code === 0 && (
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: "blue",
                                                                textDecoration: "underline",
                                                                cursor: "pointer",
                                                                mt: 1,
                                                                textAlign: "center",
                                                            }}
                                                            onClick={() => handleOpenModal(insight.troubleshoot_detailed_msg)}
                                                        >
                                                            Click here for more details
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                        </ListItemButton>
                                        {index !== value.troubleshoot_result[0].copilot_insights.length - 1 && (
                                            <Divider />
                                        )}
                                    </React.Fragment>
                                );
                            });
                        })()}
                        <ListItemText
                            primary={value.troubleshoot_result?.[0]?.co_pilot_rca}
                            sx={{
                                color:
                                    value.troubleshoot_result?.[0]?.co_pilot_rca_code === 0
                                        ? "red"
                                        : value.troubleshoot_result?.[0]?.co_pilot_rca_code === 1
                                        ? "green"
                                        : "black",
                                fontWeight: 1000,
                                textAlign: "center",
                                mt: 4,
                            }}
                        />
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setOpenInsightDialog(false)}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="sm">
                <DialogTitle>Detailed Information</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">{detailedMsg}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
