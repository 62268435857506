import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { createIpam } from '../../../API/IPAM/Ipam';
import { UserContext } from '../../../context/UserContext';
import { FormProvider, RHFTextField, RHFSubnetSelector } from '../../hook-form';


const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: 'flex-start',
    },
    topPaperScrollBody: {
        verticalAlign: 'top',
    },
    fieldContainer: { minHeight: 190 },
});

export default function AddIPAM(props) {
    const { openDialog, handleClose,gridApi } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const { customerId } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [selectedSubnets, setSelectedSubnets] = useState([]);


    const subnetRegex = /^(([12]?[0-9]{1,2}|2[0-4][0-9]|25[0-5])(\.|\/)){4}([1-2]?[0-9]|3[0-2])$/;
    const locationNameRegex = /^.*[A-Za-z0-9]$/;

    // const IpamSchema = Yup.object().shape({
    //     Region: Yup.string()
    //         .matches(locationNameRegex, 'Invalid Region name. Must start and end with a letter and can include commas, underscores, hyphens, or spaces.')
    //         .required('Region name is required'),
    //     Location: Yup.string()
    //         .matches(locationNameRegex, 'Invalid location name. Must start and end with a letter and can include commas, underscores, hyphens, or spaces.')
    //         .required('Location name is required'),
    //     Subnet: Yup.array()
    //         .of(
    //             Yup.object().shape({
    //                 value: Yup.string().matches(subnetRegex, "Invalid subnet"),
    //                 label: Yup.string()
    //             })
    //         )
    //         .min(1, "Min 1 subnet is required"),

    // });
    const IpamSchema = Yup.object().shape({
        Region: Yup.string()
            .matches(locationNameRegex, 'Invalid Region name. Must start and end with a letter and can include commas, underscores, hyphens, or spaces.')
            .required('Region name is required'),
        Location: Yup.string()
            .matches(locationNameRegex, 'Invalid location name. Must start and end with a letter and can include commas, underscores, hyphens, or spaces.')
            .required('Location name is required'),
        Subnet: Yup.string()
            .matches(subnetRegex, "Invalid subnet")
            .required("Subnet is required"),
    });
    
    const defaultValues = {
        Location: "",
        Subnet: "",
        Region: "",
        Remarks: ""
    };

    const methods = useForm({
        resolver: yupResolver(IpamSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors },
    } = methods;

  

    const onSubmit = async (value) => {
        const data = {
            location: value.Location,
            subnet_value: value.Subnet,
            region: value.Region,
            remarks: value.Remarks,
        };
        setLoading(true);
        createIpam(customerId, data)
            .then((res) => {
                console.log("res",res);

                setLoading(false);
                handleClose();
                gridApi.purgeInfiniteCache();
                enqueueSnackbar(
                    res.message,
                    {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    },
                    500
                );
            })
            .catch((res) => {
                setLoading(false);
                enqueueSnackbar(
                    res.response.data.error,
                    {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                        },
                    },
                    500
                );
            });
    };


    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                aria-labelledby="responsive-dialog-title"
                classes={{
                    scrollPaper: classes.topScrollPaper,
                    paperScrollBody: classes.topPaperScrollBody,
                }}
            >
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>
                        Add IP Address
                        <IconButton onClick={handleClose} sx={{ float: 'right' }}>
                            <CloseIcon sx={{ fontSize: '1.5rem' }} />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    {loading && <LinearProgress />}
                    <DialogContent>
                        <Stack spacing={3} sx={{ py: 1 }}>
                            {/* <RHFSubnetSelector name="subnets" setSelectedSubnets={setSelectedSubnets} selectedSubnets={selectedSubnets} /> */}
                            <RHFTextField name="Subnet" label="Subnet *" placeholder="Enter subnet in format: 192.168.1.0/24" />

                            <Stack direction="row" spacing={3}>
                                <RHFTextField name="Region" label="Region*" placeholder="Enter Region" />
                                <RHFTextField name="Location" label="Location *" placeholder="Enter Location" />
                            </Stack>
                            <Stack spacing={3} sx={{ py: 1 }}>
                                <RHFTextField name="Remarks" label="Remarks " placeholder="Remarks" multiline rows={4} />
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton type="submit" variant="contained" loading={loading}>
                            Add
                        </LoadingButton>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </>
    );
}