import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { useState, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Divider,
  createFilterOptions,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField, FormProvider, RHFSubnetSelector } from '../hook-form';
import { UserContext } from '../../context/UserContext';
import { addMacAddress } from '../../API/WifiEvents/wifi'


const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
});

const AddMacDialog = (props) => {
  const classes = useStyles();
  const { openDialog, handleClose, gridApi } = props;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);

  const macadddressRegex = /^(?:[0-9A-Fa-f]{1,2}[:\-\.]){5}[0-9A-Fa-f]{1,2}$/;

  const macAddressSchema = Yup.object().shape({
    MacAddress: Yup.string()
      .transform((value) => value.trim()) // Remove whitespace
      .matches(macadddressRegex, 'Invalid Mac Address. Ex:00:1A:2B:3C:4D:5E')
      .required('Mac Address  is required'),

  });

  const defaultValues = {
    MacAddress: ''
  };
  const methods = useForm({
    resolver: yupResolver(macAddressSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    const data = {
      client_mac_address: value.MacAddress,
    };
    setLoading(true);
    addMacAddress(customerId, data)
      .then((res) => {
        setLoading(false);
        handleClose();
        gridApi.purgeInfiniteCache();
        enqueueSnackbar(
          res.message,
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });
  };


  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      style={{ margin: 0 }}
      aria-labelledby="responsive-dialog-title"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
          Add Mac Address
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </DialogTitle>
        <Divider />
        {loading && <LinearProgress />}
        <DialogContent >
          <Stack spacing={3} sx={{ py: 2 }}>
            <RHFTextField name="MacAddress" label="Mac Address *" placeholder="Enter Mac Address" />


          </Stack>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            Note: Troubleshooting may take up to 5 minutes.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={loading} >
            TroubleShoot
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}

export default AddMacDialog