// working code
import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { saveAs } from 'file-saver';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import IgnoreLineDialog from '../../IgnoreRuleComponents/Components/IgnoreLineDialog';
import SummaryDiff from '../../DevicewiseSummary/Components/renderer/ButtonDialogs/SummaryDiff';
import GoldenSummaryDiff from '../../DevicewiseSummary/Components/renderer/ButtonDialogs/GoldenSummaryDiff';
import { useSnackbar } from 'notistack';
import { UserContext } from '../../../context/UserContext';
import { getIgnoreRule } from '../../../API/CMDBOperations';

const newStyles = {
  line: {
    wordBreak: 'break-word',
    padding: '10px 2px',
    '&:hover': {
      background: 'yellow',
    },
  },
  diffContainer: {
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: '1px',
    overflowX: 'auto',
    display: 'block',
    '& pre': { whiteSpace: 'pre-wrap' },
  },
  diffRemoved: {
    overflowX: 'auto',
    maxWidth: 300,
  },
  diffAdded: {
    overflowX: 'auto',
    maxWidth: 300,
  },
};

export default function DiffDialog001(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = useContext(UserContext);
  const { sourcePath, targetPath, data, backupDetails, cmdbGroupSummaryEvent } = props;
  const [loading, setLoading] = useState(true);
  const [sourceData, setSourceData] = useState('');
  const [targetData, setTargetData] = useState('');
  const [openIgnore, setOpenIgnore] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const queryRef = useRef('');
  const [ignoreRules, setIgnoreRules] = useState([]);

  const [diffExpanded, setDiffExpanded] = useState(true);
  const toggleDiffExpander = () => {
    setDiffExpanded(!diffExpanded);
  };


  const decodedGoldenConfig = atob(backupDetails?.golden_config || '');
  const decodedCurrentConfig = atob(backupDetails?.curr_config || '');
  const decodedCmdbGroupSummaryEvent = atob(backupDetails?.r_golden_file_difference || '');

  useEffect(() => {
    if (backupDetails?.golden_config || backupDetails?.curr_config) {
      setLoading(false);
    }
  }, [backupDetails]);

  const downloadSrcTxtFile = async () => {
    const blob = new Blob([decodedGoldenConfig], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}_${data?.r_dvc_unique_identifier}_GoldenConfig.txt`);
  };

  const downloadTarTxtFile = async () => {
    const blob = new Blob([decodedCurrentConfig], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${data?.r_grp_smry_device_type}_${data?.g_created_at}_${data?.r_dvc_unique_identifier}_CurrentConfig.txt`);
  };

  const handleLineNumberClick = (lineId, event) => {
    if (lineId) {
      setOpenIgnore(true);
    }
  };

  useEffect(() => {
    if (decodedGoldenConfig || decodedCurrentConfig) {
      setLoading(false); // Data is available.
    } else if (backupDetails) {
      setLoading(false); // API call completed, but no data is available.
    }
  }, [decodedGoldenConfig, decodedCurrentConfig, backupDetails]);
  


  useEffect(() => {
    const fetchData = async () => {
      setRefreshLoading(true);

      const payload = {
        global_keyword: queryRef.current,
        filterModel: {
          dcir_is_global: {
            filterType: 'text',
            type: '=',
            filter: '0',
          },
        },
        sortModel: {},
        startRow: 0,
        endRow: 200,
      };

      try {
        const pageCount = 1;
        const rulesData = await getIgnoreRule(customerId, payload, pageCount);

        if (rulesData?.data?.total) {
          // setTotalItems(rulesData.data.total);
        }

        if (rulesData.total === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          setIgnoreRules(rulesData?.data?.data);
          // gridApi.setRowData(rulesData?.data?.data);
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setRefreshLoading(false);
      }
    };

    fetchData();
  }, [customerId]);

  // const filterTextByContent = (text, contentToIgnore) => {
  //   return text
  //     .split("\n")
  //     .filter(
  //       (line) => !contentToIgnore.some((content) => line.includes(content))
  //     )
  //     .join("\n");
  // };

  const filterTextByContent = (text, contentToIgnore) => {
    const lines = text.split("\n");
    let result = [];
    let skipMode = false;

    lines.forEach(line => {
      // Check if we should skip lines based on LINES_BETWEEN rules
      const startRule = contentToIgnore.find(
        rule => rule.start_with && line.includes(rule.start_with)
      );
      const endRule = contentToIgnore.find(
        rule => rule.ends_with && line.includes(rule.ends_with)
      );

      if (startRule) {
        skipMode = true; // Start skipping lines
      }

      if (!skipMode) {
        // Apply LINE_CONTAIN rules
        if (!contentToIgnore.some(content => typeof content === "string" && line.includes(content))) {
          result.push(line); // Keep the line if no match is found
        }
      }

      if (endRule) {
        skipMode = false; // Stop skipping lines after hitting the end rule
      }
    });

    return result.join("\n");
  };

  const filteredIgnoreRules = ignoreRules.map(rule => {
    if (rule.dcir_rule_type === "LINE_CONTAIN") {
      return rule.dcir_contains;
    } else if (rule.dcir_rule_type === "LINES_BETWEEN") {
      return {
        start_with: rule.dcir_start_with,
        ends_with: rule.dcir_ends_with
      };
    }
    else if (rule.dcir_rule_type === "REGEX") {
      return rule.dcir_regex

    }
    return null; // For rules that do not match the conditions
  }).filter(item => item !== null); // To remove any null values


  const maskIgnoredContent = (text, contentToIgnore) => {
    return text
      .split("\n") // Split text into lines
      .map((line) => {
        // Replace all occurrences of the ignored content in the line
        contentToIgnore.forEach((content) => {
          line = line.split(content).join("IGNORE"); // Replace content with "IGNORE"
        });
        return line;
      })
      .join("\n"); // Join lines back together
  };

  const filteredOldText = filterTextByContent(decodedGoldenConfig, filteredIgnoreRules);
  const filteredNewText = filterTextByContent(decodedCurrentConfig, filteredIgnoreRules);
  const filteredGoldenText = filterTextByContent(decodedCmdbGroupSummaryEvent, filteredIgnoreRules);

  return (
    <>
      {/* {loading && <LinearProgress />} */}
      <Box sx={{ p: 2, width: '100%' }}>
        {/* <GoldenSummaryDiff cmdbGroupSummaryEvent={filteredNewText} filteredOldText={filteredOldText}/> */}
        <Grid container>
          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_GoldenConfig`}
              <Tooltip title="Download file">
                <IconButton onClick={downloadSrcTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6}>
            <Typography sx={{ pt: 1, pb: 1 }}>File name: {`${data?.r_grp_smry_device_type || data?.l_dvc_typ_name}_${data?.g_created_at}_CurrentConfig`}
              <Tooltip title="Download file">
                <IconButton onClick={downloadTarTxtFile} size="small">
                  <DownloadIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>

        {/* <Box>
          {decodedGoldenConfig || decodedCurrentConfig ? (
            <ReactDiffViewer
              compareMethod={DiffMethod.WORDS}
              oldValue={filteredOldText}
              newValue={filteredNewText}
              styles={newStyles}
              leftTitle={'Golden configuration'}
              rightTitle={'Current configuration'}
              onLineNumberClick={handleLineNumberClick}
              splitView
            />
          ) : (
            <Typography sx={{ color: 'red' }}>No data available</Typography>
          )}
          {openIgnore && (
            <IgnoreLineDialog
              gridApi={gridApi}
              openDialog={openIgnore}
              handleCloses={() => setOpenIgnore(false)}
              data={data}
            />
          )}
        </Box> */}
        <Box>
          {loading ? (
            <LinearProgress />
          ) : 
          decodedGoldenConfig || decodedCurrentConfig ? (
            <ReactDiffViewer
              compareMethod={DiffMethod.WORDS}
              oldValue={filteredOldText}
              newValue={filteredNewText}
              styles={newStyles}
              leftTitle={'Golden configuration'}
              rightTitle={'Current configuration'}
              onLineNumberClick={handleLineNumberClick}
              splitView
            />
          ) : (
            <Typography sx={{ color: 'red', textAlign: 'center', mt: 2 }}>
              No data available
            </Typography>
          )}
          {openIgnore && (
            <IgnoreLineDialog
              gridApi={gridApi}
              openDialog={openIgnore}
              handleCloses={() => setOpenIgnore(false)}
              data={data}
            />
          )}
        </Box>

      </Box>
    </>
  );
}


