import { MonitorHeart, Pause, PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useNavigate } from 'react-router-dom';
import { WithConfirm } from '../../WithConfirm';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import WifiTroubleshootInsightsDialog from '../WifiTroubleshootInsightsDialog';
import { addMacAddress } from '../../../API/WifiEvents/wifi'
import { UserContext } from '../../../context/UserContext';


const ActionRender = (props) => {
  const [openInsightDialog, setOpenInsightDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openRetroubleshootDialog, setOpenRetroubleshootDialog] = useState(false)
  const { customerId } = useContext(UserContext);

  const { data, confirm, api } = props;

  const { enqueueSnackbar } = useSnackbar();

  const handleIconClick = () => {
    setOpenInsightDialog(true);
  };

  const handleRetroubleshoot = () => {
    setOpenRetroubleshootDialog(true);
  };

  const handleCloseRetroubleshootDialog = () => {
    setOpenRetroubleshootDialog(false);
  };

  const handleConfirmRetroubleshoot = () => {

    const payload = {
      client_mac_address: data?.client_mac_address,
    };
    setLoading(true);
    addMacAddress(customerId, payload)
      .then((res) => {
        setLoading(false);
        handleCloseRetroubleshootDialog();
        api.purgeInfiniteCache();
        enqueueSnackbar(
          res.message,
          {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      })
      .catch((res) => {
        setLoading(false);
        enqueueSnackbar(
          res.message,
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          },
          500
        );
      });

  };


  if (props?.data) {
    return (
      <>
        <Box>
          {data && data.troubleshoot_status === 1 ?

            (
              <>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box display="flex" gap={1} justifyContent="center">

                    <Tooltip title="View Insights">
                      <IconButton onClick={handleIconClick}>
                        <TipsAndUpdatesIcon color="warning" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="ReTroubleshoot">
                      <IconButton onClick={handleRetroubleshoot}>
                        <TroubleshootIcon color="warning" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography variant="caption">Last updated :{data.troubleshoot_completed_at}</Typography>
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box display="flex" gap={1} justifyContent="center">
                    <Tooltip title="View Insights">
                      <IconButton >
                        <TipsAndUpdatesIcon style={{ color: 'gray' }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="ReTroubleshoot">
                      <IconButton >
                        <TroubleshootIcon style={{ color: 'gray' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Typography variant="caption" sx={{ mt: 1 }}>
                    Analysing....
                  </Typography>
                </Box>

              </>
            )
          }
        </Box>
        {data && (
          <WifiTroubleshootInsightsDialog
            value={data}
            openInsightDialog={openInsightDialog}
            setOpenInsightDialog={setOpenInsightDialog}
          />
        )}
        <Dialog
          open={openRetroubleshootDialog}
          onClose={handleCloseRetroubleshootDialog}
          maxWidth="sm" // Controls the dialog size (can be 'xs', 'sm', 'md', 'lg', 'xl')
          fullWidth // Ensures the dialog takes up the full width based on maxWidth
        >
          <DialogTitle>Confirm Retroubleshoot</DialogTitle>
          <hr />
          <DialogContent>
            {/* <DialogContentText>
              <strong style={{ color: 'red' }}>
                Warning: This action may affect ongoing processes.
              </strong>
            </DialogContentText> */}
            <DialogContentText>
              <Typography variant="body1" color="textPrimary">
                Are you sure you want to Re-troubleshoot?
              </Typography>              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                Note: Troubleshooting may take up to 5 minutes.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRetroubleshootDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmRetroubleshoot}
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

      </>
    );
  }
  return null;
};

ActionRender.propTypes = {
  data: PropTypes.object,
  confirm: PropTypes.func.isRequired,
};

export default WithConfirm(ActionRender);
