import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Breadcrumbs, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Page from '../components/Page';

import { IPAMComponents } from '../components/IPAMComponents';
import IPAMCards from '../components/IPAMComponents/components/IPAMCards';
import LocationSelect from '../components/NetworkHealthComponents/LocationSelect';
// import IPAM from 'src/components/IPAManagementComponents/IPAM';
import IPAM from '../components/IPAManagementComponents/IPAM';


//---------------------------------------------------------------------

export default function Ipam() {

  return (
    <Page title="IPM">
      <Container maxWidth={false}>

        <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb">
          <Typography component={Link} color="inherit" to="/dashboard/dashboard" sx={{ pb: 1.5 }}>
              <HomeOutlinedIcon />
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/Inventory">
              Inventory
            </Typography>
            <Typography component={Link} underline="hover" color="inherit" to="/dashboard/ipa-management">
              IPAM
            </Typography>
          </Breadcrumbs>
        </div>

        {/* <Grid container spacing={4} sx={{paddingBottom:'20px'}}>
          <Grid container item spacing={4}>
            <IPAMCards />
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <hr />
            <br />
            <LocationSelect />
          </Grid>
        </Grid> */}

      <IPAM />
        
      </Container>
    </Page>
  );
}
